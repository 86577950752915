.AppWrapper {
  width: 100%;
  overflow: hidden;
  background-image: url("/src/assets/background.jpeg");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
 
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2%;
  min-height: 100vh;
  background-color: #61D1AD80;
}

.ImagesContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.App-logo {
  max-width: 100%;
  height: auto;
  pointer-events: none;
}

.App-product {
  pointer-events: none;
}

.ContactsContainerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f0f0f0E0;
  margin-top: 5%;
}
.ContactsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}
.ContactContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .ContactContainer {
    width: 90%;
  }
}

/*first child should have a thicker left border*/
/*last child should have a thicker right border*/
.ContactContainer:first-child {
  border-left-width: 4px;
}
.ContactContainer:last-child {
  border-right-width: 4px;
}


.ContactTitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}
.ContactSubstitle {
  font-size: 1rem;
}

.AvailableSoon {
  font-size: 2.5rem;
  font-weight: bolder;
  padding-bottom: 2%;
  color: #52a48a;
}